<template>
<div>
    <div
        id="kt_subheader"
        class="kt-subheader kt-grid__item"
    >
        <div class="kt-container kt-container--fluid">
            <div class="kt-subheader__main">
                <h3
                    v-if="deviceType !== 'laptop'"
                    class="kt-subheader__title"
                >
                    <SVGIcon
                        hex-color="#0f88ef"
                        class="mt-2 mr-3"
                        name="teachers"
                    />
                    Teachers
                </h3>
                <div class="kt-input-icon kt-input-icon--right kt-subheader__search mt-1">
                    <input
                        v-model="searchString"
                        type="text"
                        class="form-control"
                        placeholder="Filter..."
                        @keyup="currentPage = 1"
                    >
                    <span class="kt-input-icon__icon kt-input-icon__icon--right">
                        <span>
                            <i class="flaticon2-search-1" />
                        </span>
                    </span>
                </div>

                <SubHeaderMarkingPeriodPicker />
            </div>

            <div class="kt-subheader__toolbar">
                <div class="kt-subheader__wrapper mt-1">
                    <div class="paginator-container">
                        <div class="kt-pagination kt-pagination--brand">
                            <a
                                v-if="deviceType !== 'mobile'"
                                class="mr-3"
                                :class="limitBy == 'Linked' ? 'btn btn-success' : 'btn kt-subheader__btn-secondary'"
                                @click.stop.prevent="selectLimitBy('Linked')"
                            >
                                Linked Accounts
                            </a>
                            <a
                                v-if="deviceType !== 'mobile'"
                                class="mr-3"
                                :class="limitBy == 'Unlinked' ? 'btn btn-danger' : 'btn kt-subheader__btn-secondary'"
                                @click.stop.prevent="selectLimitBy('Unlinked')"
                            >
                                Unlinked Accounts
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div
        class="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid"
        style="overflow: visible;"
    >
        <div
            v-if="isNycGoogleDomainUser"
            class="row"
        >
            <div class="col">
                <div class="alert alert-outline-primary alert-light alert-elevate fade show">
                    <div class="alert-icon">
                        <i class="flaticon-questions-circular-button" />
                    </div>
                    <div class="alert-text">
                        The district requires that all teachers log in and grant SyncGrades permissions to google data.
                        To complete this step, all teachers should log in and connect to google. Credentials persists across school
                        terms, so this step is only required once per teacher.
                    </div>
                </div>
            </div>
        </div>

        <RecycleScroller
            v-slot="{ item }"
            class="scroller"
            :items="filteredTeachers"
            :item-size="170"
            key-field="schoolStaffId"
            item-class="my-1"
        >
            <TeacherHeaderPortlet
                :show-portlet-header="false"
                :teacher="item"
                :school-term-marking-period-id="schoolTermMarkingPeriodId"
            />
        </RecycleScroller>
    </div>
</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import TeacherHeaderPortlet from '../components/TeacherHeaderPortlet.vue';
import SubHeaderMarkingPeriodPicker from '../components/SubHeaderMarkingPeriodPicker.vue';
import teacherMixins from '../store/mixins/teacherMixins';
import Types from '../store/Types';

export default {
    name: 'TeacherList',
    components: {
        TeacherHeaderPortlet,
        SubHeaderMarkingPeriodPicker,
    },
    mixins: [teacherMixins],
    data() {
        return {
            key: 0,
            perPage: 100,
            currentPage: 1,
            searchString: '',
            optionsHovering: false,
            showOptionsDropDown: false,
            limitBy: null,
            loading: false,
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            deviceType: (state) => state.deviceType,
            schoolTermId: (state) => state.user.school.schoolTermId,
        }),
        ...mapGetters({
            isNycGoogleDomainUser: Types.getters.IS_NYC_GOOGLE_DOMAIN_USER,
        }),
        totalRecords() {
            return this.filteredTeachers.length;
        },
        rows() {
            return this.filteredTeachers.length;
        },
        schoolTermMarkingPeriodId() {
            return this.$store.state.settings.schoolTermMarkingPeriodId;
        },
        filteredTeachers() {
            const { searchString, limitBy } = this;
            return this.$_teacherMixins_getTeachers().filter((t) => {
                if (limitBy == null) return true;
                if (limitBy == 'Unlinked') return !t.hasGoogleAccount;
                if (limitBy == 'Linked') return t.hasGoogleAccount;
                return true;
            }).filter((t) => {
                if (searchString == '') return true;
                return (t.search.indexOf(searchString.toLowerCase()) > -1);
            });
        },
    },
    methods: {
        selectLimitBy(t) {
            if (this.limitBy == t) {
                this.limitBy = null;
                return;
            }
            this.limitBy = t;
        },
    },
};

</script>

<style scoped>
.kt-grid__item--fluid {
    overflow-x: hidden;
}
.scroller {
    height: 100% !important;
}
</style>
