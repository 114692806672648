var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "kt-subheader kt-grid__item",
        attrs: { id: "kt_subheader" },
      },
      [
        _c("div", { staticClass: "kt-container kt-container--fluid" }, [
          _c(
            "div",
            { staticClass: "kt-subheader__main" },
            [
              _vm.deviceType !== "laptop"
                ? _c(
                    "h3",
                    { staticClass: "kt-subheader__title" },
                    [
                      _c("SVGIcon", {
                        staticClass: "mt-2 mr-3",
                        attrs: { "hex-color": "#0f88ef", name: "teachers" },
                      }),
                      _vm._v(" Teachers "),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass:
                    "kt-input-icon kt-input-icon--right kt-subheader__search mt-1",
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchString,
                        expression: "searchString",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", placeholder: "Filter..." },
                    domProps: { value: _vm.searchString },
                    on: {
                      keyup: function ($event) {
                        _vm.currentPage = 1
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.searchString = $event.target.value
                      },
                    },
                  }),
                  _vm._m(0),
                ]
              ),
              _c("SubHeaderMarkingPeriodPicker"),
            ],
            1
          ),
          _c("div", { staticClass: "kt-subheader__toolbar" }, [
            _c("div", { staticClass: "kt-subheader__wrapper mt-1" }, [
              _c("div", { staticClass: "paginator-container" }, [
                _c(
                  "div",
                  { staticClass: "kt-pagination kt-pagination--brand" },
                  [
                    _vm.deviceType !== "mobile"
                      ? _c(
                          "a",
                          {
                            staticClass: "mr-3",
                            class:
                              _vm.limitBy == "Linked"
                                ? "btn btn-success"
                                : "btn kt-subheader__btn-secondary",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.selectLimitBy("Linked")
                              },
                            },
                          },
                          [_vm._v(" Linked Accounts ")]
                        )
                      : _vm._e(),
                    _vm.deviceType !== "mobile"
                      ? _c(
                          "a",
                          {
                            staticClass: "mr-3",
                            class:
                              _vm.limitBy == "Unlinked"
                                ? "btn btn-danger"
                                : "btn kt-subheader__btn-secondary",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.selectLimitBy("Unlinked")
                              },
                            },
                          },
                          [_vm._v(" Unlinked Accounts ")]
                        )
                      : _vm._e(),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
    _c(
      "div",
      {
        staticClass:
          "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
        staticStyle: { overflow: "visible" },
      },
      [
        _vm.isNycGoogleDomainUser
          ? _c("div", { staticClass: "row" }, [_vm._m(1)])
          : _vm._e(),
        _c("RecycleScroller", {
          staticClass: "scroller",
          attrs: {
            items: _vm.filteredTeachers,
            "item-size": 170,
            "key-field": "schoolStaffId",
            "item-class": "my-1",
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ item }) {
                return [
                  _c("TeacherHeaderPortlet", {
                    attrs: {
                      "show-portlet-header": false,
                      teacher: item,
                      "school-term-marking-period-id":
                        _vm.schoolTermMarkingPeriodId,
                    },
                  }),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "span",
      { staticClass: "kt-input-icon__icon kt-input-icon__icon--right" },
      [_c("span", [_c("i", { staticClass: "flaticon2-search-1" })])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col" }, [
      _c(
        "div",
        {
          staticClass:
            "alert alert-outline-primary alert-light alert-elevate fade show",
        },
        [
          _c("div", { staticClass: "alert-icon" }, [
            _c("i", { staticClass: "flaticon-questions-circular-button" }),
          ]),
          _c("div", { staticClass: "alert-text" }, [
            _vm._v(
              " The district requires that all teachers log in and grant SyncGrades permissions to google data. To complete this step, all teachers should log in and connect to google. Credentials persists across school terms, so this step is only required once per teacher. "
            ),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }